<div class="daysi-drawer daysi-drawer-mobile bg-gray-100">
  <input #drawer id="drawer" type="checkbox" class="daysi-drawer-toggle" />
  <div class="daysi-drawer-content flex flex-col items-center justify-center h-screen">
    <div class="container flex flex-col" style="height: 100% !important;">

      <div class="flex-none">
        <header aria-label="Header del base-layout" class="bg-white rounded-xl border p-3 mt-2 w-full">
          <div class="w-full px-4 sm:px-6 lg:px-8">
            <div *ngIf="localStorageService.authenticated" class="flex justify-between">
              <div class="flex items-center justify-start">
                <button class="text-black" (click)="clickShowSidebar()" *ngIf="showSidebar === false">
                  <span class="material-symbols-outlined">
                    menu
                  </span>
                </button>
              </div>
              <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button
                [matMenuTriggerFor]="menu">
                <div *ngIf="imageToShowDialog; else sinFoto">
                  <img [src]="imageToShowDialog" alt="Foto de perfil" class="shadow rounded-full"
                    *ngIf="imageToShowDialog">
                </div>
                <ng-template #sinFoto>
                  <mat-icon style="font-size: 40px" class="text-black">account_circle</mat-icon>
                </ng-template>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <div fxLayout="row wrap" fxLayoutAlign="center center">

                <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button>
                  <div *ngIf="imageToShowDialog; else sinFoto">
                    <img [src]="imageToShowDialog" alt="Foto de perfil" class="shadow rounded-full"
                      *ngIf="imageToShowDialog">
                  </div>
                  <ng-template #sinFoto>
                    <mat-icon style="font-size: 40px" class="text-black">account_circle</mat-icon>
                  </ng-template>
                </button>
                <div class="mt-3" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="height: 80px">
                  <div class="text-black text-lg font-bold font-sans">{{usuario?.userData.nombre}}
                    {{usuario?.userData.apellido}}</div>
                  <div class="font-semibold text-sm inline-block py-1 px-3 m-2 rounded-full bg-blue-700text-white">
                    {{usuario?.userName}}
                  </div>
                </div>
              </div>

              <button mat-menu-item (click)="openSettings()">
                <mat-icon color="primary">verified_user
                  <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
                </mat-icon>
                <span>Cuenta</span>
              </button>

              <button mat-menu-item (click)="logout()">
                <mat-icon color="primary">logout
                  <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
                </mat-icon>
                <span>Salir</span>
              </button>
            </mat-menu>
          </div>
        </header>
      </div>

      <div class="flex-1">
        <router-outlet></router-outlet>
      </div>

      <div class="flex-none mt-2">
        <app-footer></app-footer>
      </div>


    </div>

  </div>

  <div #sidebar class="daysi-drawer-side" *ngIf="showSidebar">
    <label for="drawer" class="daysi-drawer-overlay"></label>
    <div class="flex flex-col justify-between bg-white w-80 border-r lg:hidden">
      <div class="px-4 py-6">

        <div class="flex justify-end items-center">
          <button class="text-black" (click)="clickCloseSidebar()">
            <span class="material-symbols-outlined">
              close
            </span>
          </button>
        </div>
        <span class="flex justify-center items-center rounded-lg">
          <img height="40" width="40" src="assets/images/Favicon-logo.png" alt="Logo Coopeduc">
        </span>

        <nav aria-label="Main Nav" class="flex flex-col mt-3 space-y-1">

          <details class="group is-published [&_summary::-webkit-details-marker]:hidden" *ngxPermissionsOnly="[
                                        'ADM/mant_persona/list206',
                                        'ADM/mant_usuario/list200',
                                        'ADM/mant_rol/list255',
                                        'ADM/mant_parametro/list231',
                                        'ADM/mant_modulo/list239',
                                        'ADM/mant_auditoria/list236',
                                        'ADM/mant_auditoria/list237',
                                        'ADM/mant_configuraciones_relojes/list285'
                                        ]">
            <summary
              class="flex items-center px-4 py-3 text-gray-500 rounded-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700">
              <mat-icon mat-list-icon class="text-black">admin_panel_settings</mat-icon>
              <span class="ml-3 text-xl font-bold text-black"> Administración </span>
              <span class="ml-3 transition duration-300 shrink-0 group-open:-rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </summary>

            <nav aria-label="Teams Nav" class="mt-1.5 ml-8 flex flex-col">

              <div *ngxPermissionsOnly="'ADM/mant_persona/list206'" [routerLink]="['/administracion/personas']"
                routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">people</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Personas </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'ADM/mant_persona/list206'"></mat-divider>

              <div *ngxPermissionsOnly="'ADM/mant_usuario/list200'" [routerLink]="['/administracion/usuarios']"
                routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">admin_panel_settings</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Usuarios </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'ADM/mant_usuario/list200'"></mat-divider>

              <div *ngxPermissionsOnly="'ADM/mant_rol/list255'" [routerLink]="['/administracion/roles']"
                routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">privacy_tip</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Roles </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'ADM/mant_rol/list255'"></mat-divider>

              <div *ngxPermissionsOnly="['ADM/mant_auditoria/list236', 'ADM/mant_auditoria/list237']"
                [routerLink]="['/administracion/auditorias']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon mat-list-icon class="text-black">gavel</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Auditorías </span>
              </div>
              <mat-divider> *ngxPermissionsOnly="['ADM/mant_auditoria/list236',
                'ADM/mant_auditoria/list237']"</mat-divider>

              <div *ngxPermissionsOnly="'ADM/mant_parametro/list231'"
                [routerLink]="['/administracion/parametros-sistema']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon mat-list-icon class="text-black">grid_view</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Parámetros del Sistema </span>
              </div>

            </nav>
          </details>

          <mat-divider></mat-divider>

          <div *ngxPermissionsOnly="'ADM/mant_parametro/list231'" [routerLink]="['/parametricos']"
            routerLinkActive="bg-green-100"
            class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 hover:text-gray-700 cursor-pointer">
            <mat-icon mat-list-icon class="text-black">settings</mat-icon>
            <span class="ml-3 text-xl font-bold text-black"> Paramétricos </span>
          </div>

          <mat-divider *ngxPermissionsOnly="'ADM/mant_parametro/list231'"></mat-divider>

          <div *ngxPermissionsOnly="'ORG/mant_organizacion/list300'" [routerLink]="['/organizacion']"
            routerLinkActive="bg-green-100"
            class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 hover:text-gray-700 cursor-pointer">
            <mat-icon mat-list-icon class="text-black">public</mat-icon>
            <span class="ml-3 text-xl font-bold text-black"> Organización </span>
          </div>

          <mat-divider *ngxPermissionsOnly="'ORG/mant_organizacion/list300'"></mat-divider>

          <details class="group is-published [&_summary::-webkit-details-marker]:hidden" *ngxPermissionsOnly="[
                                        'SUM/mant_entrada_cabecera_insumo/list400',
                                        'SUM/mant_factura_insumo/list405',
                                        'SUM/mant_remision_insumo/list410',
                                        'SUM/mant_solicitud_cabecera_insumo/list425',
                                        'SUM/mant_aprobacion/list430',
                                        'SUM/mant_solicitud_entrega/list435',
                                        'SUM/mant_existencia_insumo/list440',
                                        'SUM/mant_solicitud_cabecera_servicio/list462'

                                        ]">
            <summary
              class="flex items-center px-4 py-3 text-gray-500 rounded-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700">
              <mat-icon mat-list-icon class="text-black">dynamic_form</mat-icon>
              <span class="ml-3 text-xl font-bold text-black w-32"> Pedidos </span>
              <span class="ml-3 justify-end transition duration-300 shrink-0 group-open:-rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </summary>

            <nav aria-label="Teams Nav" class="mt-1.5 ml-8 flex flex-col">

              <div *ngxPermissionsOnly="'SUM/mant_entrada_cabecera_insumo/list400'"
                [routerLink]="['/contratos-suministros/entradas']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">create_new_folder</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Entradas </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_entrada_cabecera_insumo/list400'"></mat-divider>

              <div *ngxPermissionsOnly="'SUM/mant_remision_insumo/list410'"
                [routerLink]="['/contratos-suministros/remisiones']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">receipt_long</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Remisiones </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_remision_insumo/list410'"></mat-divider>

              <div *ngxPermissionsOnly="[
                                            'SUM/mant_factura_insumo/list405',
                                            'SUM/mant_solicitud_combustible_factura/list486',
                                            'SUM/mant_solicitud_servicio_factura/list472'
                                            ]" [routerLink]="['/contratos-suministros/facturas']"
                routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">shopping_cart_checkout</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Facturas </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_factura_insumo/list405'"></mat-divider>

              <div *ngxPermissionsOnly="[
                                            'SUM/mant_solicitud_cabecera_insumo/list425',
                                            'SUM/mant_solicitud_detalle_servicio/list467',
                                            'SUM/mant_solicitud_combustible/list481'
                                            ]" [routerLink]="['/contratos-suministros/solicitudes']"
                routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">format_list_bulleted_add</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Solicitudes </span>
              </div>
              <mat-divider *ngxPermissionsOnly="[
                                            'SUM/mant_solicitud_cabecera_insumo/list425',
                                            'SUM/mant_solicitud_detalle_servicio/list467'
                                            ]"></mat-divider>

              <div *ngxPermissionsOnly="'SUM/mant_solicitud_entrega/list435'"
                [routerLink]="['/contratos-suministros/solicitudes-entregas']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">add_task</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Entregas </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_solicitud_entrega/list435'"></mat-divider>

              <div *ngxPermissionsOnly="'SUM/mant_solicitud_entrega/list435'"
                [routerLink]="['/contratos-suministros/elegir-tipo']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">pending_actions</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Solicitudes pendientes </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_solicitud_entrega/list435'"></mat-divider>

              <div *ngxPermissionsOnly="'SUM/mant_aprobacion/list430'"
                [routerLink]="['/contratos-suministros/aprobaciones']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">check_small</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Aprobaciones </span>
              </div>
              <mat-divider *ngxPermissionsOnly="'SUM/mant_aprobacion/list430'"></mat-divider>

              <div *ngxPermissionsOnly="'SUM/mant_existencia_insumo/list456'"
                [routerLink]="['/contratos-suministros/existencias']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">query_stats</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Existencias </span>
              </div>

              <div *ngxPermissionsOnly="'SUM/mant_existencia_control_detalle/list500'"
                [routerLink]="['/contratos-suministros/control-existencia']" routerLinkActive="bg-green-100"
                class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 cursor-pointer">
                <mat-icon class="text-black">assignment_turned_in</mat-icon>
                <span class="ml-3 text-xl font-bold text-black "> Control Existencia </span>
              </div>

            </nav>
          </details>

          <mat-divider *ngxPermissionsOnly="'SUM/mant_existencia_insumo/list456'"></mat-divider>

          <div *ngxPermissionsOnly="[
                                            'REP/rep_alerta_minima_stock/list600',
                                            'REP/rep_quiebre_stock/list601',
                                            'REP/rep_stock/list602'
                                            ]" [routerLink]="['/reportes']" routerLinkActive="bg-green-100"
            class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 hover:text-gray-700 cursor-pointer">
            <mat-icon mat-list-icon class="text-black">install_desktop</mat-icon>
            <span class="ml-3 text-xl font-bold text-black"> Reportes </span>
          </div>

            <mat-divider *ngxPermissionsOnly="'ARCH/mant_cierre_caja_diario/list800'"></mat-divider>

            <div *ngxPermissionsOnly="[
                                            'ARCH/mant_cierre_caja_diario/list800',
                                            'ARCH/mant_cierre_caja_diario/get801'
                                            ]" [routerLink]="['/archivos-digitales']" routerLinkActive="bg-green-100"
            class="flex items-center px-4 py-3 text-gray-500 rounded-lg hover:bg-gray-200 hover:text-gray-700 cursor-pointer">
            <mat-icon mat-list-icon class="text-black">insert_drive_file</mat-icon>
            <span class="ml-3 text-xl font-bold text-black"> Archivos Digitales </span>
          </div>


        </nav>
      </div>
    </div>

  </div>
</div>